.slider_jumbo {

    margin: 0 auto;
    line-height: 0px;

    .slick-prev,
    .slick-next { display: none !important; }

    .slide_wrapper { position: relative; }
    
        .slide-placeholder { width: 100%; }

    .slide_caption {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 5px;
        width: 60%;
        padding: 0 15px;
        line-height: 1em;

        h2 {
            margin: 10px 0;
            @include font-size(42);
            line-height: 1em;
            text-transform: uppercase;
            color: $color-primary;
        }

        small {
            @include font-size(16);
            line-height: 1em;
            color: $color-secondary;
        }

        p { 
            margin: 0;
            @include font-size(15);
            line-height: 1em;
            color: #888;
            strong { color: $color-text; }
        }

        @include media-breakpoint-up(sm) {
            h2 { @include font-size(48); }
            small { @include font-size(18); }
            p { @include font-size(17); }
        }
        @include media-breakpoint-up(lg) {
            h2 { @include font-size(55); }
            small { @include font-size(24); }
            p { @include font-size(20); }
        }

        @include media-breakpoint-up(xl) {
            h2 { @include font-size(60); }
            small { @include font-size(24); }
            p { @include font-size(22); }
        }

    }

    .slick-slide:nth-child(even) .slide_caption {
        left: auto; right: 5px;
        text-align: right;
    }

    @include media-breakpoint-up(sm) {
        .slide-placeholder { opacity: 0; }
        .slide { background-size: cover; }
        .slide_1 { background-image: url("/_/images/slides/jumbo/1.webp"); }
        .slide_2 { background-image: url("/_/images/slides/jumbo/2.webp"); }
        .slide_3 { background-image: url("/_/images/slides/jumbo/3.webp"); }
        .slide_4 { background-image: url("/_/images/slides/jumbo/4.webp"); }
    }

}